const bitsListItems = [
	{ label: 'Free', value: 'free' },
	{ label: '1', value: 'ticket_1' },
	{ label: '10', value: 'ticket_10' },
	{ label: '25', value: 'ticket_25' },
	{ label: '50', value: 'ticket_50' },
	{ label: '75', value: 'ticket_75' },
	{ label: '100', value: 'ticket_100' },
	{ label: '125', value: 'ticket_125' },
	{ label: '150', value: 'ticket_150' },
	{ label: '175', value: 'ticket_175' },
	{ label: '200', value: 'ticket_200' },
	{ label: '250', value: 'ticket_250' },
	{ label: '300', value: 'ticket_300' },
	{ label: '400', value: 'ticket_400' },
	{ label: '500', value: 'ticket_500' },
	{ label: '750', value: 'ticket_750' },
	{ label: '1000', value: 'ticket_1000' },
	{ label: '1250', value: 'ticket_1250' },
	{ label: '1500', value: 'ticket_1500' },
	{ label: '2000', value: 'ticket_2000' },
	{ label: '2500', value: 'ticket_2500' },
	{ label: '3000', value: 'ticket_3000' },
	{ label: '5000', value: 'ticket_5000' },
	{ label: '7500', value: 'ticket_7500' },
	{ label: '10000', value: 'ticket_10000' },
];

const freeOnlyItems = [{ label: 'Free', value: 'free' }];

export { bitsListItems, freeOnlyItems };
