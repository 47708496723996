<template>
	<v-menu offset-y class="bits-btn">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="secondary"
				:depressed="depressed"
				class="px-0"
				small
				dark
				v-bind="attrs"
				:disabled="disabled"
				v-on="on">
				<template #default>
					<div class="d-flex align-center">
						<div class="d-flex align-center px-1 px-md-1 px-lg-1" :class="xl && `px-xl-${xl}`">
							<template v-if="data.sku">
								<img class="mr-1" width="15" :src="getBitIcon(sku)" />
								<span>{{ getBitValue(parseBits(data.sku)) }}</span>
							</template>
						</div>
						<v-divider vertical class="mx-2"></v-divider>
						<v-icon class="mr-2">
							{{ icons.arrow }}
						</v-icon>
					</div>
				</template>
			</v-btn>
		</template>
		<v-list v-if="listItems.bits.length" dense color="purple" class="bits-dropdown">
			<v-list-item v-for="item in listItems.bits" :key="item.sku" @click="actions.changeBits(item)">
				<img class="mr-1" width="20" :src="getBitIcon(item.label)" />
				{{ getBitValue(parseBits(item.label)) }}
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { ref, reactive, onMounted, watch } from '@vue/composition-api';
import { isBitsEnabled } from '../../utils/auth';
import { parseBits } from '../../utils/sticker';
import { bitsListItems, freeOnlyItems } from '../../utils/lists';
import { mdiChevronDown } from '@mdi/js';
import store from '@/store';

export default {
	props: {
		sku: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		block: {
			type: Boolean,
			default: false,
		},
		solo: {
			type: Boolean,
			default: false,
		},
		depressed: {
			type: Boolean,
			default: false,
		},
		tts: {
			type: Boolean,
			default: false,
		},
		isGlobalTts: {
			type: Boolean,
			default: false,
		},
		voiceId: {
			type: String,
			default: null,
		},
		xl: {
			type: Number,
			default: 10,
		},
	},
	emits: ['onChange'],
	setup(props, ctx) {
		// Default values.
		const data = reactive({
			sku: props.sku,
		});

		// If the voice is changed on a card, we need to reload minimum bits.
		watch(
			() => props.voiceId,
			() => {
				actions.setBits();
			},
		);

		const listItems = reactive({
			bits: [],
		});

		const minBits = ref(null);

		onMounted(() => {
			actions.setBits();
		});

		const getBitIcon = sku => {
			const bits = parseBits(sku);

			if (bits <= 999) {
				return require('@/assets/icons/bits/100.png');
			}
			if (bits > 999 && bits <= 4999) {
				return require('@/assets/icons/bits/1k.png');
			}
			if (bits > 4999 && bits <= 9999) {
				return require('@/assets/icons/bits/5k.png');
			}
			if (bits > 9999 && bits <= 99999) {
				return require('@/assets/icons/bits/10k.png');
			}
			if (bits > 99999) {
				return require('@/assets/icons/bits/10k.png');
			}

			return require('@/assets/icons/bits/100.png');
		};

		const getBitValue = value => {
			if (value) {
				if (value.toLowerCase() === 'free') {
					return 'Free';
				}

				return Number(value).toLocaleString();
			}
			return;
		};

		const actions = {
			// Called after the # of bits is selected.
			changeBits: function (item) {
				data.sku = item.value;
				ctx.emit('onChange', item);
			},

			setBits() {
				if (props.voiceId) {
					const voices = store.state.app.ttsVoices;
					const voice = voices.find(v => v.id === props.voiceId);

					if (voice) {
						minBits.value = voice.minBits;
					}
				}

				// If the TTS is global, set the minimum bits to 100.
				if (props.isGlobalTts) {
					minBits.value = 100;
				}

				// No bits streamers cannot set bits to stickers.
				listItems.bits = bitsListItems;

				if (isBitsEnabled.value) {
					listItems.bits = bitsListItems;
				} else {
					listItems.bits = freeOnlyItems;
					data.sku = 'free';
				}

				if ((props.voiceId && props.tts) || props.isGlobalTts) {
					actions.filterBits();
				}
			},

			filterBits() {
				if (props.tts || props.isGlobalTts) {
					// Remove the Free items from array
					listItems.bits = listItems.bits.filter(item => item.value !== 'free');

					// Set Minimum Bits in the Dropdown
					if (minBits.value && typeof minBits.value === 'number' && minBits.value >= 0) {
						listItems.bits = listItems.bits.filter(item => Number(item.label) >= minBits.value);

						if (listItems.bits.length) {
							data.sku = listItems.bits[0].value;
						} else {
							data.sku = null;
						}

						if (props.sku) {
							const sku = listItems.bits.find(sk => sk.value === props.sku);
							if (sku) {
								data.sku = sku.value;
							}
						}
					}
				}
			},
		};

		return {
			actions,
			listItems,
			data,
			minBits,
			parseBits,
			getBitIcon,
			getBitValue,
			icons: {
				arrow: mdiChevronDown,
			},
		};
	},
};
</script>

<style scoped>
.bits-btn {
	position: relative;
}

.bits-dropdown {
	height: 200px;
	overflow-y: scroll;
}
</style>
